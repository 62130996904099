import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { reverseUserRoute } from "routes";
import store from "index";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { fetchCustomers } from "actions/customerActions";
import { fetchOrderByID, updateOrderOutbound } from "actions/orderActions";

// Material UI
import {
  makeStyles, Button, Typography, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, List, ListItem, ListItemText, TextField,
  CircularProgress
} from "@material-ui/core";

// Project Components
import { Page, HeaderAndSubtitle } from "components";

// Local Components
import Products from "../components/1Products";
import Destination from "../components/2Destination";

import { ValidatorForm } from "react-material-ui-form-validator";
import { UID } from 'AppConstants';
import { get } from "lodash";

import { useCbOnce } from "utils/useCbOnce";

export const EditOutbound = (props) => {
  const classes = useStyles();
  const [orderResult, setOrderResult] = useState({});
  const [open, setOpen] = useState(false);
  const [pendingOrderSKUs, setPendingOrderSKUs] = useState([]);
  const [pricingErrorText, setPricingErrorText] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event) => {
    if (event.target.innerText === "CANCEL") {
      setOpen(false);
    }

    if (event.target.innerText === "SAVE") {
      skuPricingValidation(true);
    }
  };

  function itemPricingChange(value, id, type) {

    const skusArr = [...pendingOrderSKUs];
    skusArr.forEach((element) => {
      if (element.item.id === id && type === "price") {
        console.log('element', element)
        element.sale_price = value;
        element.sale_currency = element.sale_currency;
      }
    });
    setPendingOrderSKUs(skusArr);
    skuPricingValidation();
  };

  function skuPricingValidation(closeModal) {

    let pricingError = false;
    setPricingErrorText("");
    pendingOrderSKUs.forEach((element, index) => {

      if ((!element.sale_price || 0 === element.sale_price.length || isNaN(element.sale_price) || element.sale_price < 1) &&
        (!element.bundle_sale_price || 0 === element.bundle_sale_price.length || isNaN(element.bundle_sale_price || element.bundle_sale_price < 1))) {
        setPricingErrorText("Please enter a valid sale price, it should be minimum $1.");
        pricingError = true;
      }
    });
    if (closeModal && !pricingError) {
      setOpen(false);
    }
  }

  const [editObject, setEditObject] = useState({});
  const updateOrderLoading = useSelector(
    (state) => state.orderReducer.updateOrderLoading
  );

  const updateOrderErrors = useSelector(
    (state) => state.orderReducer.updateOrderErrors || state.orderReducer.error
  );
  const customers = useSelector((state) => state.customersReducer.customers);
  let order = useSelector((state) => state.orderReducer.fetchedOrder);
  let inputRef = React.createRef("form");

  const dispatch = useDispatch();
  const { match, countries, updateCountries, warehouses } = props;
  const { id } = match.params;
  const editableStatuses = ['info_received', 'no_stock', 'amending', 'unconfirmed', 'awaiting_arrival']

  const availableShippingOptions = ["AusPost STD 3-7 Business Days",
    "AusPost STD + Sign 3-7 Business Days",
    "AusPost STD + Insured + Sign 5-8 Business Days",
    "AusPost EXP 2-5 Business Days",
    "AusPost EXP + Sign 2-5 Business Days",
    "AusPost EXP + Insured + Sign 2-5 Business Days",
    "AusPost INT STD 6-10 Business Days",
    "AusPost INT STD + Insured 6-10 Business Days",
    "AusPost INT EXP 3-7 Business Days",
    "AusPost INT EXP + Insured 3-7 Business Days",
    "DHL EXP 2-5 Business Days",
    "FedEx EXP 2-5 Business Days",
    "TNT Road 3-7 Business Days",
    "TNT Overnight 1-2 Business Days",
    "TNT SDAY 3-5 hours",
    "StarTrack Road 3-7 Business Days",
    "StarTrack Overnight Sign 1-2 Business Days",
    "StarTrack Overnight 1-2 Business Days",
    "Pickup 2-4pm",
    "Domestic letters"];

  const createShippingOptions = () => {
    let thisOrdersOptions = availableShippingOptions;
    thisOrdersOptions.includes(order.shipping_method)
      ? (thisOrdersOptions = availableShippingOptions)
      : thisOrdersOptions.push(order.shipping_method);

    return availableShippingOptions.map((option) => ({
      value: option,
      text: option,
    }));
  };

  const DestinationInputs = [
    {
      id: "warehouse_id",
      label: "KeepSpace Warehouse",
      required: true,
      type: "native-select",
      options: warehouses,
    },
    {
      id: "contact_name",
      label: "Name",
      required: true,
    },
    {
      id: "company_name",
      label: "Company Name",
      required: false,
    },
    {
      id: "contact_phone",
      label: "Phone Number",
      required: false,
    },
    {
      id: "address",
      label: "Address line 1",
      required: true,
    },
    {
      id: "address2",
      label: "Address line 2",
      required: false,
    },
    {
      id: "suburb",
      label: "Suburb",
      required: true,
    },
    {
      id: "city",
      label: "Postcode",
      required: true,
    },
    {
      id: "state",
      label: "State",
      required: true,
    },
    {
      id: "country",
      label: "Country",
      required: true,
      type: "native-select",
      options: countries,
    },
    {
      id: "email",
      label: "Email",
      required: true,
    },
    {
      id: "reference",
      label: "Reference / Order Number",
      required: true,
    },
    {
      id: "shipping_method",
      label: "Shipping Method",
      required: true,
      type: "native-select",
      options: createShippingOptions(),
    }
  ];

  const AutosuggestFields = {
    display: {
      title: {
        label: "Name: ",
        value: "name",
      },
      subtitle: [
        {
          label: "Company name: ",
          value: "company_name",
        },
      ],
    },
    // match: ['company_name', 'name'],
    match: ["name"],
    value: "name",
    placeholder: "Search customers",
  };

  useEffect(() => {
    dispatch(fetchOrderByID(id))
  }, []);

  useEffect(() => {

    if (customers.length === 0) dispatch(fetchCustomers());
  }, [customers.length, id, order.id]);

  useEffect(() => {
    if (order.booking_items === undefined) return;

    // set EditObject from order object
    let orderObject = { id: id, customer_id: get(order, 'customer_id', null)  }
    DestinationInputs.forEach(
      (attr) => (orderObject[attr.id] = order[attr.id])
    );

    if (orderObject.country != "") {
      countries.forEach(function (country, index) {
        if (country.text == orderObject.country) {
          orderObject.country = country.value
        }
      })
    }
    setEditObject(orderObject);

    // set PendingOrderSKU from order object
    let bookingItems = order.booking_items.map((booking_item) => {
      let orderSku = {
        item: booking_item.sku || booking_item.item_id,
        quantity: booking_item.quantity_ordered,
        sale_currency: booking_item.sale_currency || '',
        sale_price: booking_item.sale_price || "",
        bundle_sku: booking_item.bundle_sku,
        bundle_sale_currency: booking_item.bundle_sale_currency,
        bundle_sale_price: booking_item.bundle_sale_price
      };
      return orderSku;
    });
    setPendingOrderSKUs(bookingItems);
  }, [createShippingOptions().length, id, order]);

  const submit = useCbOnce((event) => {
    event.preventDefault();
    let booking = editObject;
    Object.keys(booking).map(k => booking[k] = typeof booking[k] == 'string' ? booking[k].trim() : booking[k]);
    let internationalOrder = international_order(booking.country)
    let isValidObj = true;

    let items = pendingOrderSKUs.map((pendingOrderSKU) => ({
      id: pendingOrderSKU.item.id,
      quantity: pendingOrderSKU.quantity,
      ...saleDetails(pendingOrderSKU, internationalOrder)
    }));

    if (internationalOrder) {
      items.forEach((bookingItem) => {
        bookingItem.sale_price = parseInt(bookingItem.sale_price) || "";
        if (bookingItem.sale_price === "") {
          bookingItem.sale_currency = ""
        }
        if (!bookingItem.sale_price && !bookingItem.bundle_sale_price) {
          setPricingErrorText("Please provide the missing values");
          isValidObj = false
        }
      });
    }
    if (isValidObj) {
      dispatch(updateOrderOutbound(booking, items)).then((result) => {
        if (result) {
          // result is null when in error
          setOrderResult(result);
          return true;
        }
        else return false
      });
    }
    else {
      handleClickOpen();
    }
  });

  if (order.batch_id || ((order.status) && (!editableStatuses.includes(order.status)))) {
    return <Redirect to={{ pathname: `/user/${UID}/orders/summary/${id}/details/overview`, errorMessage: 'You cannot edit this order' }} />
  }

  const quantityVariation = (id, quantityVariation) => {
    let newBookingItems = pendingOrderSKUs.map((bookingItem) =>
      (bookingItem.item && bookingItem.item.id === id)
        ? {
          item: bookingItem.item,
          quantity: bookingItem.quantity + quantityVariation,
          sale_currency: bookingItem.sale_currency || '',
          sale_price: bookingItem.sale_price || bookingItem.item.retail_cost || ""
        }
        : bookingItem
    );
    setPendingOrderSKUs(
      newBookingItems.filter(
        (bookingItem) =>
          bookingItem.quantity > 0 || bookingItem.quantity === null
      )
    );
  };

  const addBookingItems = arrayOfProducts => {
    let newArray = pendingOrderSKUs

    arrayOfProducts.forEach(product => {
      newArray = addProducts(newArray, product.item_id, product.item_quantity)
      return newArray
    })

    if (international_order(editObject.country)) setOpen(true)

    setPendingOrderSKUs(newArray);
  };

  const addProducts = (array, id, qty) => {
    let newBookingItem = true;
    let bookingItemObj = array ? array.find(bookingItem => bookingItem.item && bookingItem.item.id === id) : null;
    if (bookingItemObj) newBookingItem = false;

    if (!newBookingItem) {
      let bookingItemsUpdatedQty = array.map((bookingItem) =>
        (bookingItem.item && bookingItem.item.id === id)
          ? {
            item: bookingItem.item,
            quantity: qty + bookingItem.quantity,
            sale_currency: bookingItem.sale_currency || order.order_currency,
            sale_price: bookingItem.sale_price || bookingItem.item.retail_cost || ""
          }
          : bookingItem
      );

      return bookingItemsUpdatedQty
    }

    const item = store.getState().spaceReducer.items.find(item => item.id === id);

    if (item) {
      let newItem = {
        item: item,
        quantity: qty,
        sale_currency: (bookingItemObj) ? bookingItemObj.sale_currency : order.order_currency,
        sale_price: (bookingItemObj) ? bookingItemObj.sale_price : item.retail_cost

      };
      return [...array, newItem]
    }
  }

  const handleSelectCustomer = (customer) => {
    //excluding warehouse from the fields that needs to be changed based upon selected customer;
    const fields = DestinationInputs.slice(1).map((h) => h.id);
    let updatedObject = Object.assign({}, editObject);
    updatedObject['customer_id'] = customer?.id;

    fields.forEach((key) => {
      switch (key) {
        case "contact_name":
          updatedObject[key] = customer.name;
          break;
        case "city":
          updatedObject[key] = customer.postcode;
          break;
        case "address":
          updatedObject[key] = customer.address_line_1;
          break;
        case "address2":
          updatedObject[key] = customer.address_line_2;
          break;
        case "contact_phone":
          updatedObject[key] = customer.phone;
          break;
        case "shipping_method":
          break;
        case "reference":
          break;
        default:
          updatedObject[key] = customer[key];
          break;
      }
    });
    setEditObject(updatedObject);
  };

  const handleChange = (event, name, val) => {
    setEditObject({ ...editObject, [name || event.target.id]: val || event.target.value });
    if (event && event.target.id === "country" && international_order(event.target.value) && pendingOrderSKUs.length) {
      handleClickOpen();
    }
  };

  const saleDetails = (item, internationalOrder) => {
    return internationalOrder ?
      {
        sale_currency: item.sale_currency,
        sale_price: item.sale_price || '',
        bundle_sku: item.bundle_sku,
        bundle_sale_currency: item.bundle_sale_currency,
        bundle_sale_price: item.bundle_sale_price
      } : {}
  }




  const international_order = country => {
    return country && !['AUSTRALIA', 'AU', 'AUS', 'AUSTR', '', '.', '..'].includes(country.toUpperCase())
  }

  return (

    // <Animate in style={{ transformOrigin: '0 0 0' }} >
    <Page title="Edit Outbound" className={classes.root}>
      {orderResult.id ? (
        <Redirect
          to={`${reverseUserRoute("Orders")}summary/${orderResult.id}/details/`}
        />
      ) : null}

      <HeaderAndSubtitle
        header="Order / Summary"
        subtitle="Edit Outbound Order"
      />

      {/* Form Part 1 */}
      <Products
        key="products"
        pendingOrderSKUs={pendingOrderSKUs}
        quantityVariation={quantityVariation}
        addBookingItems={addBookingItems}
        orderType="outbound"
        pricingModal={handleClickOpen}
        formState={editObject}
      />

      <ValidatorForm
        onSubmit={submit}
        onError={(errors) => console.log(errors)}
        ref={inputRef}
      >
        {/* Form Part 2 */}
        <Destination
          key="destination"
          formInputs={DestinationInputs}
          formState={editObject}
          formErrors={updateOrderErrors}
          onChange={handleChange}
          orderType="outbound"
          searchInput={customers}
          handleSelect={handleSelectCustomer}
          autosuggestFields={AutosuggestFields}
          countries={countries}
          updateCountries={updateCountries}
        />

        {updateOrderErrors ? (
          <Typography color="error" gutterBottom={true}>{updateOrderErrors.messages || updateOrderErrors.message || updateOrderErrors}</Typography>
        ) : null}

        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            props.history.push(`/user/${UID}/orders/summary/${order.id}/details`)
          }
          style={{ marginRight: 10 }}
          disabled={updateOrderLoading}
        >
          <Typography color="inherit">Cancel</Typography>
        </Button>
        {/* Submit */}
        <div className={classes.wrapper}>
          <Button type="submit" variant="contained" color="primary" disabled={updateOrderLoading}>
            <Typography color="inherit">Save changes</Typography>
          </Button>
          {updateOrderLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </ValidatorForm>

      <Dialog
        disableBackdropClick
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth='md'
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Typography variant="h4" component="h3">
            Commercial Invoice
          </Typography>
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText>
            <List dense={true}>

              {pendingOrderSKUs && pendingOrderSKUs.map((item, index) => (
                <>
                  <ListItem>
                    <ListItemText className={classes.itemPricingSection}>
                      {index + 1}. {item.item.title}
                    </ListItemText>

                    {!item.bundle_sale_price && <>
                      <ListItemText className={classes.itemPricingSection}>
                        <TextField label="Sale price" className={(pricingErrorText) ? classes.errorField : ''} onChange={(e) => itemPricingChange(e.target.value, item.item.id, "price")} defaultValue={item.sale_price} />
                      </ListItemText>
                      <ListItemText className={classes.itemPricingSection}>
                        <TextField label="Sale currency" className={classes.formControl} defaultValue={item.sale_currency} disabled={true} />
                      </ListItemText>
                      <ListItemText className={classes.itemPricingSection}></ListItemText>
                    </>}
                    {item.bundle_sale_price && <>
                      <ListItemText className={classes.itemPricingSection}>
                        <TextField label="Bundle Sale Price *" disabled={true} defaultValue={item.bundle_sale_price} />
                      </ListItemText>
                      <ListItemText className={classes.itemPricingSection}>
                        <TextField label="Sale currency" className={classes.formControl} defaultValue={item.bundle_sale_price} disabled={true} />
                      </ListItemText>
                      <ListItemText className={classes.itemPricingSection}>
                        <TextField label="Bundle SKU *" disabled={true} defaultValue={item.bundle_sku} />
                      </ListItemText>
                    </>}
                  </ListItem>
                </>

              ))}

            </List>
            {pricingErrorText && <DialogContentText className={classes.errorText}>{pricingErrorText}</DialogContentText>}

          </DialogContentText>
          <div className={classes.subtitle}>
            <Typography>It is a legal requirement to fill out a commercial invoice for all international shippings.</Typography>
            <Typography>If not specified otherwise, the Sale Price (provided by Ecommerce store) or Retail cost (from Keepspace Product page) will be used to declare products' values.</Typography>
            <Typography>Here you can re-adjust these values if the product was sold for a discounted price, higher price, in a foreign currency etc.</Typography>
            {pendingOrderSKUs && pendingOrderSKUs.find(item => item.bundle_sale_price) && <>
              <Typography>* Products sold as a bundle will appear on the customs declaration as one bundle product .</Typography>
            </>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

    </Page>
    // </Animate>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  itemPricingSection: {
    width: '120px',
    paddingBottom: '15px',
    margin: '10px 10px'
  },
  formControl: {
    width: '100%'
  },
  errorField: {
    borderColor: 'red'
  },
  errorText: {
    color: 'red',
    textAlign: "center"
  },
  menuPaper: {
    maxHeight: 250
  },
  subtitle: {
    padding: '10px 18px',
    fontStyle: 'italic'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    display: 'inline'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default EditOutbound;