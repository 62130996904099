import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Typography, Button} from '@material-ui/core';
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import {Link as RouterLink} from 'react-router-dom';
import {reverseUserRoute} from 'routes';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const HeaderDetailsPage = props => {
  const {className, menu, title, path, ...rest} = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Button
        size="small"
        component={RouterLink}
        to={reverseUserRoute(path)}
        variant="outlined"
        style={{marginBottom: '10px', color: '#717273', fontSize: 12}}
      >
        <ArrowBackIos style={{height: 10}} />
        Back
      </Button>
      <Typography
        component="h2"
        gutterBottom
        variant="overline"
      >
        {menu}
      </Typography>
      <Typography
        component="h1"
        variant="h4"
      >
        {title}
      </Typography>
    </div>
  );
};

HeaderDetailsPage.propTypes = {
  className: PropTypes.string
};

export default HeaderDetailsPage;
