import React from "react";
import { Redirect } from "react-router-dom";
import { reverseUserRoute } from "routes";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  modSkuQuantityToOrder,
  setNewOrderInProgress,
  addFormDataToOrder,
  sendOrderInbound,
} from "actions/orderActions";
import { fetchSuppliers } from "actions/supplierActions";
import keepspaceApi from "services/keepspaceApi";
import { Link } from "react-router-dom";

// Material UI
import {
  makeStyles,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";

// Project Components
import { Page, HeaderAndSubtitle } from "components";

// Local Components
import Products from "../components/1Products";
import Destination from "../components/2Destination";
import AdditionalDetails from "../components/3AdditionalDetails";

import { ValidatorForm } from "react-material-ui-form-validator";

export const AddNewInbound = (props) => {
  const classes = useStyles();
  const [orderResult, setOrderResult] = React.useState({});
  const pendingOrderSKUs = useSelector(
    (state) => state.orderReducer.newOrderItems
  );
  const newOrderObject = useSelector(
    (state) => state.orderReducer.newOrderObject
  );
  const newOrderErrors = useSelector(
    (state) => state.orderReducer.newOrderErrors
  );
  const newOrderPushing = useSelector(
    (state) => state.orderReducer.newOrderPushing
  );
  const suppliers = useSelector((state) => state.suppliersReducer.suppliers);
  const dispatch = useDispatch();
  const { countries, warehouses } = props;
  const [shippingCarriers, setShippingCarriers] = React.useState([]);
  let inputRef = React.createRef("form");

  React.useEffect(() => {
    dispatch(setNewOrderInProgress(true, "inbound"));
    dispatch(addFormDataToOrder("country", "Australia"));
    dispatch(fetchSuppliers());
  }, [dispatch]);

  React.useEffect(() => {
    let unmounted = false;
    if (shippingCarriers.length === 0) {
      keepspaceApi
        .getShippingCarriers()
        .then((json) => {
          if (json.shipping_carriers && !unmounted)
            setShippingCarriers(json.shipping_carriers);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    return () => {
      unmounted = true;
    };
  }, [shippingCarriers.length]);

  const quantityVariation = (id, quantityVariation) =>
    dispatch(modSkuQuantityToOrder(id, quantityVariation));

  const handleSelectSupplier = (supplier) => {
    const fields = DestinationInputs.map((h) => h.id);
    // storing supplier_id in newOrderObject;
    dispatch(addFormDataToOrder('supplier_id', supplier?.id));

    fields.forEach((key) => {
      switch (key) {
        case "company_name":
          dispatch(addFormDataToOrder(key, supplier.name));
          break;
        case "city":
          dispatch(addFormDataToOrder(key, supplier.postcode));
          break;
        case "address":
          dispatch(addFormDataToOrder(key, supplier.address_line_1));
          break;
        case "address2":
          dispatch(addFormDataToOrder(key, supplier.address_line_2));
          break;
        case "contact_phone":
          dispatch(addFormDataToOrder(key, supplier.phone));
          break;
        default:
          dispatch(addFormDataToOrder(key, supplier[key]));
          break;
      }
    });
  };

  const DestinationInputs = [
    {
      id: "company_name",
      label: "Supplier Company",
      required: false,
    },
    {
      id: "contact_name",
      label: "Suppliers Contact Name",
      required: true,
    },
    {
      id: "email",
      label: "Email Address",
      required: true,
    },
    {
      id: "contact_phone",
      label: "Phone Number",
      required: true,
    },
    {
      id: "address",
      label: "Address Line 1",
      required: true,
    },
    {
      id: "address2",
      label: "Address Line 2",
      required: false,
    },
    {
      id: "city", // postcode is stored in the city column
      label: "Postcode",
      required: true,
    },
    {
      id: "suburb",
      label: "Suburb",
      required: true,
    },
    {
      id: "state",
      label: "State",
      required: true,
    },
    {
      id: "country",
      label: "Country",
      required: true,
      type: "native-select",
      options: countries,
    },
  ];

  const AdditionalDetailsInputs = [
    {
      id: "warehouse_id",
      label: "KeepSpace Warehouse",
      required: true,
      type: "native-select",
      options: warehouses,
    },
    {
      id: "shipper_id",
      label: "Carrier Company",
      type: "native-select",
      options: shippingCarriers,
      required: false,
    },
    {
      id: "reference",
      label: "Waybill Number (Reference)",
      required: true,
    },
    {
      id: "tracking_id",
      label: "Tracking Number",
      required: false,
    },
    {
      id: "eta",
      label: "Estimated Arrival Date",
      required: true,
      type: "date",
      minDate: new Date(),
    },
  ];

  const AutosuggestFields = {
    display: {
      title: {
        label: "Display name: ",
        value: "display_name",
      },
      subtitle: [
        {
          label: "Company name: ",
          value: "name",
        },
        {
          label: "Contact name: ",
          value: "contact_name",
        },
      ],
    },
    match: ["contact_name", "name", "display_name"],
    value: "name",
    placeholder: "Search suppliers",
  };

  const handleChange = (event, id, value) => {
    console.log("handleChange - ", event, id, value)
    const fieldId = event ? event.target.id : id;
    const val = event ? event.target.value : value;
    dispatch(addFormDataToOrder(fieldId, val));
  };

  const submit = (e) => {
    e.preventDefault();
    let booking = newOrderObject;
    Object.keys(booking).map(k => booking[k] = typeof booking[k] == 'string' ? booking[k].trim() : booking[k]);
    let items = pendingOrderSKUs.map((pendingOrderSKU) => ({
      id: pendingOrderSKU.item.id,
      quantity: pendingOrderSKU.quantity,
    }));
   
    dispatch(sendOrderInbound(booking, items)).then((result) => {
      if (result) {
          setOrderResult(result);
          return true;
      }
      else return false;
    })
  };

  return (
    // <Animate in style={{ transformOrigin: '0 0 0' }} >
    <Page title="Add New Inbound" className={classes.root}>
      {orderResult.id ? (
        <Redirect
          to={`${reverseUserRoute("Orders")}summary/${orderResult.id}/details/`}
        />
      ) : null}

      <HeaderAndSubtitle
        header="Order / Summary"
        subtitle="New Inbound Order"
      />

      {/* Form Part 1 */}
      <Products
        key="products"
        pendingOrderSKUs={pendingOrderSKUs}
        quantityVariation={quantityVariation}
        orderType="inbound"
        formState={newOrderObject}
      />

      <ValidatorForm
        onSubmit={submit}
        onError={(errors) => console.log(errors)}
        ref={inputRef}
      >
        {/* Form Part 2 */}
        <Destination
          key="destination"
          formInputs={DestinationInputs}
          formState={newOrderObject}
          formErrors={newOrderErrors}
          onChange={handleChange}
          orderType="inbound"
          searchInput={suppliers}
          handleSelect={handleSelectSupplier}
          autosuggestFields={AutosuggestFields}
          {...props}
        />

        {/* Form Part 3 */}
        <AdditionalDetails
          key="additionalDetails"
          formInputs={AdditionalDetailsInputs}
          formState={newOrderObject}
          formErrors={newOrderErrors}
          onChange={handleChange}
        />
        <div className={classes.errors}>
          {newOrderErrors ? (
            <Typography color="error">
              {newOrderErrors.messages ||
                Object.keys(newOrderErrors)
                  .map((err) => `${err}: ${newOrderErrors[err]}`)
                  .join("\n")}
            </Typography>
          ) : null}
        </div>

        {/* Submit */}
        <Button
          variant="contained"
          color="secondary"
          to={reverseUserRoute("Orders:Summary")}
          component={Link}
          style={{ marginRight: 10 }}
          disabled={newOrderPushing}
        >
          <Typography color="inherit">Cancel</Typography>
        </Button>
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={newOrderPushing}
          >
            <Typography color="inherit">Create Order</Typography>
          </Button>
          {newOrderPushing && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </ValidatorForm>
    </Page>
    // </Animate>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
    display: "inline",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  errors: {
    marginTop: 20,
    marginBottom: 40,
  },
}));

export default AddNewInbound;
