import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';
import {
    Grid,
    Hidden,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';

import preval from 'preval.macro'

import HeaderImage from 'assets/img/undraw_growth_analytics_8btt.svg'
import {HashLink as Link} from 'react-router-hash-link';

import {apiPath} from 'AppConstants';
import keepspaceApi from 'services/keepspaceApi';

class Changelog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            server_hash: ''
        }
    }

    componentDidMount() {
        let hash;
        keepspaceApi.getMetaServerHash()
            .then(result => {
                hash = result['hash'];
                this.setState({
                    server_hash: hash
                })
            })
            .catch(error => console.log(error))
    }

    newFeatures = [

        {
            featureName: `Multi User and Multi Organisation Access`,
            featureDescription:
                `You are now able to add additional accounts for your team members to the platform. 
            You can also create new organisations and easily switch between them, using just one set of login credentials.`,

        },

        {
            featureName: `Fedex Integration`,
            featureDescription: `We have integrated with Fedex! As a result, we have introduced new fields for your products on the profile page. Country of manufacture HS Code (for International Shipments) and Insurance preferences.`,

        },

        {
            featureName: `Bundles`,
            featureDescription:
                `Similar to Products, Bundles also have their unique identifier: SKU.
            You can define which Products make up a bundle, so that when an order with bundle comes to our system,
            it will automatically break it into the individual SKUs. The Bundle SKU name will not appear in the order.`,

        },


    ]

    upcomingFeatures = [

        {
            featureName: `Multi User Access Restriction`,
            featureDescription:
                `You do not wanna show everything to everyone in your company?
                We got you covered, it is already under development and will be live before Christmas Eve.`,
            estimate: `Coming Next Update`
        },

        {
            featureName: `Adding batches to your SKU's`,
            featureDescription:
                `Does your SKU have a shelf life?
                Do you often sell multiple of it in one order, but it can have very minor manufacturing differences across manufacturing runs?
                Assign a batch number, and if your customer orders 12 of something, we'll ensure they all came from the same manufacturing batch.`
        },


        {
            featureName: `Automatic Stock Level Synchronization`,
            featureDescription:
                `Do you sell from more than one website or storefront? Managing the levels of stock between
                those can be messy and tricky. This new optional feature in KeepSpace is being developed that will update
                your stock levels across your integrations.
                Never again should you worry about over-selling an out-of-stock item.`,

        },
        {
            featureName: `New Integrations`,
            featureDescription:
                `We're always adding new platforms to our list of supported integrations.
                Next we'll be adding Amazon, among others.
                Please contact us if you have another in mind you would like to see!`,
        },
    ]

    changelogs = [

        {
            version: `3.3-release`,
            releaseDate: `November, 2020`,
            releaseNotes:
                <span>
                    <p>Multi User Access</p>
                </span>
        },

        {
            version: `3.2-release`,
            releaseDate: `September, 2020`,
            releaseNotes:
                <span>
                    <p>Fedex Integration</p>
                </span>
        },

        {
            version: `3.1.2-release`,
            releaseDate: `August, 2020`,
            releaseNotes:
                <span>
                    <p>Added Bundles and Units of cartons</p>
                </span>
        },

        {
            version: `3.1.1-release`,
            releaseDate: `December 21st, 2019`,
            releaseNotes:
                <span>
                    <p>All KeepSpace users migrated to the new platform, new features become active</p>

                </span>,
        },
        {
            version: `3.1.0-beta2`,
            releaseDate: `November 6th, 2019`,
            releaseNotes:
                <span>
                    <p>Second Release to a few more KeepSpace parteners for testing</p>
                    <br />

                    <p>New features include:</p>

                    <ul>
                        <li>* Newly designed SKU page</li>
                        <li>* Newly designed orders page and order summary</li>
                        <li>* Improved messaging platform</li>
                        <li>* Mobile-Friendly Interface</li>
                        <li>* Greatly improved loading times</li>
                        <li>* Analytics Platform (Beta)</li>
                        <li>* Brand new overview interface</li>
                    </ul>

                </span>
        },
        {
            version: `3.1.0-beta1`,
            releaseDate: `30th September, 2019`,
            releaseNotes: `First release to a small list of KeepSpace partners for testing`,
        },
        {
            version: `3.0.0-alpha`,
            releaseDate: `5th May, 2019`,
            releaseNotes: `Internal version being developed`,
        },
    ]

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>

                <Grid
                    container
                    className={classes.introduction}
                    spacing={3}
                >

                    <Grid item md={6} sm={12}>

                        <Typography variant='h1'>What's new with KeepSpace?
                        </Typography>

                        <br />

                        <Typography variant='body1'>
                            We like to be open about our journey and how we do things.
                            Starting from now, we're going to be keeping a log of what we've done,
                            what's been fixed, and what you can look forward to in the near future.
                        </Typography>

                        <br />

                        <Typography variant='body1'>
                            Our new platform will be receiving frequent updates, so be sure to check in every now and then.
                        </Typography>

                        <br />

                        <Typography variant="body2">
                            We're always wanting to hear your feedback on how we can improve.
                            You can message the team by clicking on the chat bubble at the bottom of the window.
                        </Typography>

                        <br />

                        <Typography variant="body2">
                            If you would like to preview beta features before the they get released to the public,
                            get in contact with us and we'll add you to the beta testing program
                        </Typography>

                        <br />

                    </Grid>

                    <Hidden smDown>
                        <Grid item md={6}>
                            <img
                                alt="Cover"
                                className={classes.image}
                                src={HeaderImage}
                            />
                        </Grid>
                    </Hidden>

                </Grid>

                <Typography variant='h2'>Newly Released Features</Typography>
                <br />
                <Typography variant='body1'>
                    This list will contain a highlight of our most recent features.
                    For the full list, you'll want to look at the <Link smooth to='/changelog#changelog'>Complete Changelog</Link>
                </Typography>
                <Paper className={classes.paper}>
                    <div className={classes.content}>
                        {this.newFeatures.map(feature => (
                            <div className={classes.feature} key={feature.featureName}>
                                <div className={classes.featureTitle}>
                                    <Typography variant='h4'>{feature.featureName}</Typography>
                                    {
                                        feature.estimate ? <Typography variant='subtitle2'> - {feature.estimate}</Typography> : null
                                    }
                                </div>
                                <Typography variant='body1'>{feature.featureDescription}</Typography>
                            </div>
                        ))}
                    </div>
                </Paper>

                <Typography variant='h2'>Upcoming Features</Typography>
                <br></br>
                <Typography variant='body1'>The KeepSpace team are working hard to bring you these features and more</Typography>
                <Paper className={classes.paper}>
                    <div className={classes.content}>
                        {this.upcomingFeatures.map(feature => (
                            <div className={classes.feature} key={feature.featureName}>
                                <div className={classes.featureTitle}>
                                    <Typography variant='h4'>{feature.featureName}</Typography>
                                    {
                                        feature.estimate ? <Typography style={{marginLeft: 8}} variant='subtitle2'> - {feature.estimate}</Typography> : null
                                    }
                                </div>
                                <Typography variant='body1'>{feature.featureDescription}</Typography>
                            </div>
                        ))}
                    </div>
                </Paper>

                <Typography id='changelog' variant='h2'>Complete Changelog</Typography>
                <Paper className={classes.paper} >
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Version</TableCell>
                                <TableCell>Release Date</TableCell>
                                <TableCell>Release Notes</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.changelogs.map(changelog => (
                                <TableRow key={changelog.version}>
                                    <TableCell>{changelog.version}</TableCell>
                                    <TableCell>{changelog.releaseDate}</TableCell>
                                    <TableCell><Typography variant='body1'>{changelog.releaseNotes}</Typography></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>

                <Typography id='changelog' variant='h2'>Application Build Info</Typography>
                <br />

                <Typography variant='body1'>
                    We'll even show you what's running under the hood, for those that are curious!
                </Typography>

                <Paper className={classes.paper} >
                    <div className={classes.content}>
                        <div className={classes.feature}>
                            <div className={classes.featureTitle}>
                                <Typography variant='h4'>Build Time</Typography>
                            </div>
                            <Typography variant='body1'>This app was last compiled: {preval`module.exports = new Date().toLocaleString();`}</Typography>
                            <Typography variant='body1'>Git Hash: #{process.env.REACT_APP_GIT_SHA}</Typography>
                        </div>

                        <div className={classes.feature}>
                            <div className={classes.featureTitle}>
                                <Typography variant='h4'>Current API Server</Typography>
                            </div>
                            <Typography variant='body1'>URI: {apiPath}</Typography>
                            <Typography variant='body1'>Git Hash: #{this.state.server_hash}</Typography>
                        </div>


                    </div>
                </Paper>
            </div>
        )
    }
}

const styles = theme => ({
    root: {
        padding: 43,
        maxWidth: 1361,
    },
    introduction: {
        marginBottom: theme.spacing(3),
        maxWidth: '1241px',
        paddingTop: '50px',
        paddingLeft: '65px'
    },
    paper: {

        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(4),
    },
    table: {

    },
    content: {
        padding: theme.spacing(3),
    },
    feature: {
        marginBottom: theme.spacing(4),
    },
    featureTitle: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'baseline'

    }
})

export default withStyles(styles)(Changelog);
