
import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Typography, Tab, Tabs, Container, Card, CardMedia, Box} from '@material-ui/core';
import Header from 'components/Header/Header';
import {Content} from './content';
import {connect} from 'react-redux';
import {UID} from 'AppConstants';


function TabPanel(props) {
  const classes = useStyles();
  const {children, value, index, ...other} = props;

  const checkForLinks = (text) => {
    const updatedText = text.replace(":uid", props.uId || UID);
    return updatedText;
  };
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Container maxWidth="md" className={classes.contentWrapper}>

            <Card className={classes.video}><CardMedia
              component="iframe"
              className={classes.cover}
              src={Content[index].video}
              title={Content[index].title}
            />
            </Card>
            <Box className={classes.content}>
              <Typography variant="h1" component="h2" className={classes.videoTitle} >{Content[index].title}</Typography>
              <ol className={classes.contentList}>
                {Content[index].children.map(child => (
                  <><Typography variant={child.tag} component={child.tag} className={classes.contentText}
                    color="secondaryText" dangerouslySetInnerHTML={{__html: checkForLinks(child.text)}}></Typography>
                    {child.children && <ol className={classes.contentSubList}>
                      {child.children.map(c => (
                        <Typography variant={c.tag} component={c.tag} className={classes.contentSubText}
                          color="secondaryText" dangerouslySetInnerHTML={{__html: checkForLinks(c.text)}}></Typography>
                      ))}
                    </ol>}</>
                ))}
              </ol>
            </Box>
          </Container>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  rootWrapper: {
    padding: theme.spacing(3),
    backgroundColor: "#fff"
  },
  root: {
    flexGrow: 1,
    width: '100%',
    //backgroundColor: theme.palette.background.paper,
  },
  content: {
    padding: theme.spacing(2),
  },
  contentText: {
    fontSize: "1.4em",
    color: "#556F7B",
    margin: "20px 0",
    lineHeight: "1.8em",
    listStyle: "decimal",
  },
  contentSubText: {
    fontSize: "1.4em",
    color: "#556F7B",
    lineHeight: "1.8em",
    whiteSpace: "pre-wrap",
    margin: "0 0px 10px 25px",
    listStyle: "disc"
  },
  contentList: {
    "& li": {
      marginLeft: 35
    },
    "& a": {
      color: "#507BEE"
    }
  },
  contentSubList: {
    "& li": {
      marginLeft: 60
    }
  },
  video: {
    marginBottom: 40
  },
  cover: {
    height: 450,
  },
  videoTitle: {
    marginBottom: 60
  },
  tabsWrapper: {
    borderBottom: "1px solid #D5DDE0"
  },
  contentWrapper: {
    padding: theme.spacing(4)
  },
  tabs: {
    width: 120,
    padding: "10px 0",
    fontWeight: "bold",
    marginRight: theme.spacing(4),
    color: "#A6B1B6",
    fontFamily: [
      'Roboto'
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff'
    },
    '&:focus': {
      color: '#40a9ff',
    },
  }
}));

const GettingStarted = (props) => {
  //export default function GettingStarted() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.rootWrapper}>
      <Header title="Support" subtitle="Getting Started" />
      <div className={classes.root}>
        <div position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Getting started tabs"
            className={classes.tabsWrapper}
          >
            <Tab label="SKUS" {...a11yProps(0)} className={classes.tabs} />
            <Tab label="BUNDLES" {...a11yProps(1)} className={classes.tabs} />
            <Tab label="INBOUNDS" {...a11yProps(2)} className={classes.tabs} />
            <Tab label="OUTBOUNDS" {...a11yProps(3)} className={classes.tabs} />
            <Tab label="ANALYTICS" {...a11yProps(4)} className={classes.tabs} />
          </Tabs>
        </div>
        <TabPanel value={value} index={0} {...props} />
        <TabPanel value={value} index={1} {...props} />
        <TabPanel value={value} index={2} {...props} />
        <TabPanel value={value} index={3} {...props} />
        <TabPanel value={value} index={4} {...props} />
      </div>
    </div>
  );
}
const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  uId: state.organisationsReducer.currentOrganisation.uid,
})


export default connect(mapStateToProps)(GettingStarted);
