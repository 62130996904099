import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  TextField,
  Button
} from '@material-ui/core';
import { createTerminology } from 'actions/terminologyActions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const NewTerminology = props => {
  const { handleClose } = props;
  const dispatch = useDispatch();

  const classes = useStyles();

  const [values, setValues] = useState({title: '', details: ''});
  const [valid, setValid] = useState('')

  const handleFieldChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  const handleSave = e => {
    e.preventDefault();
    if (values.title.length === 0 || values.details.length === 0) {
        setValid("This field is required")
    } else {
        dispatch(createTerminology(values));
        handleClose();
    }
  }


  return (
      <Card className={classes.root}>
        <form>
          <CardContent>
            <Typography
              align="center"
              gutterBottom
              variant="h4"
            >
              New Terminology
            </Typography>
              <TextField
                fullWidth
                label='Title'
                name='title'
                onChange={handleFieldChange}
                value={values.title}
                variant="outlined"
                required
                id='title'
                style={{marginTop: '10px'}}
                helperText={values.title ? "" : valid}
              />
              <TextField
                fullWidth
                label='Details'
                name='details'
                onChange={handleFieldChange}
                value={values.details}
                variant="outlined"
                required
                id='Details'
                multiline={true}
                helperText={values.details ? "" : valid}
                style={{marginTop: '10px'}}
                rows={5}
              />
          </CardContent>

          <CardActions className={classes.actions}>
            <Button
              onClick={handleClose}
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
            <Button
              onClick={handleSave}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </CardActions>
        </form>
      </Card>
  );
};

NewTerminology.displayName = 'NewTerminology';

NewTerminology.propTypes = {
  className: PropTypes.string,
  handleClose: PropTypes.func,
};


export default NewTerminology;
